import React, { useState,useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
//import "animate.css/animate.css";
import $ from 'jquery'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL} from '../common/site/constants'


const PopularSearchDynamic = (props) => {
  //Get values from refine search
  const [ptype,setPtype] = useState(false)
  const [searchbed,setsearchbed] = useState(false)
 
  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = 'London'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText=''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if(props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL          = '/property/for-sale/'
  }
  if(props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL          = '/property/to-rent/'
  }
  if(props.searchtype == 'newhomesales') {
    searchaction = 'for Sale'
    URL          = '/property/new-homes/for-sale/'
    typeText = 'New Property'
  }
  if(props.searchtype == 'newhomerentals') {
    searchaction = 'to Rent'
    URL          = '/property/new-homes/to-rent/'
    typeText = 'New Property'
  }
  if(props.searchPtype) {
    ptypeURL   = '/type-'+props.searchPtype
    typeText     = capitalizeFirstLetter(props.searchPtype).replace("Buy-to-let-investments", "Buy to let investments")
  }
  if(props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield+' Bedroom '+typeText
    bedURL   = '/'+props.searchBedroomfield+'-and-more-bedrooms'
  }
  
  if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
    searcharea = props.Searcharea.areas
    searchareaText = props.Searcharea.areas.replace(/%20/g, " ")
  }

  return (<React.Fragment>
        <Row>
          {props.searchBedroomfield ?
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-london${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in North London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-east-finchley${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in East Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-barnet${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Barnet</Link></li>
          </ul>
          </Col>
          :<Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london${ptypeURL}`}>{typeText} {searchaction} in London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-london${ptypeURL}`}>{typeText} {searchaction} in North London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-east-finchley${ptypeURL}`}>{typeText} {searchaction} in East Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-barnet${ptypeURL}`}>{typeText} {searchaction} in Barnet</Link></li>
          </ul>
          </Col>
          }

          {searcharea ?
          <Col md={6} lg={3}>
            {props.searchBedroomfield || props.searchPtype ?
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}${ptypeURL}/1-and-more-bedrooms`}>1 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}${ptypeURL}/2-and-more-bedrooms`}>2 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}${ptypeURL}/3-and-more-bedrooms`}>3 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}${ptypeURL}/4-and-more-bedrooms`}>4 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}${ptypeURL}/5-and-more-bedrooms`}>5 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
            </ul> :
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-bungalow`}>Bungalows {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-apartment`}>Apartments {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-house`}>Houses {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-buy-to-let-investments`}>Buy to Let Investments {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            </ul>
            }
          </Col>:
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-bungalow`}>Bungalows {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-apartment`}>Apartments {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-house`}>Houses {searchaction} in {area}</Link></li>
          </ul>
          </Col>
          }


          {searcharea ?
          <Col md={6} lg={3}>
            {props.searchBedroomfield || props.searchPtype ?
            <ul>
            {props.searchPtype &&
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}`}>Property {searchaction} in {searchareaText}</Link></li>
            }

            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-bungalow`}>Bungalows {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-apartment`}>Apartments {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-house`}>Houses {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-buy-to-let-investments`}>Buy to Let Investments {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            </ul> :
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/1-and-more-bedrooms`}>1 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/2-and-more-bedrooms`}>2 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/3-and-more-bedrooms`}>3 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/4-and-more-bedrooms`}>4 Bedroom Property {searchaction} in {capitalizeFirstLetter(searchareaText)}</Link></li>
            </ul>
            }
          </Col>:
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-bungalow`}>Bungalows {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-apartment`}>Apartments {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-house`}>Houses {searchaction} in {area}</Link></li>
          </ul>
          </Col>
          }




          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${AREA_GUIDES_PAGE_URL.alias}`}>{capitalizeFirstLetter(searchareaText)} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${ABOUT_PAGE_URL.alias}`}>About Martyn Gerrard</Link></li>
            <li><Link className="footer_scroll_top" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>{capitalizeFirstLetter(searchareaText)} Property Valuation</Link></li>
          </ul>
          </Col>
        </Row>



  </React.Fragment>)

}

export default PopularSearchDynamic;
