import React, { useState, useEffect, PropTypes } from "react"
import Autosuggest from 'react-autosuggest';
import _ from "lodash"
import axios from 'axios';
import './assets/styles/_index.scss';
import $ from 'jquery'
import { navigate } from "@reach/router"

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => `${suggestion.name}`;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.name}
  </div>
);

class SearchResultBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      areas: [],
      aliasVal: "",
      isChages: false
    };
  }

  onChange = (event, { newValue }) => {

    this.setState({
      value: newValue,
      aliasVal: newValue,
      isChages: true
    }, this.props.handleChange(newValue));

    
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  suggestionSelected = (e, { suggestion }) => {

    //console.log("suggestion ==>", suggestion);

    if (suggestion) {
      this.setState({
        value: `${suggestion.name}`,
        //aliasVal: this.props.department === "commercial" ? suggestion.slug : suggestion.name,
        aliasVal: suggestion.slug,
      }, this.props.handleChange(suggestion.slug));

      if ( this.props.setAreaVal ){
          this.props.setAreaVal(suggestion.slug)
      }
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      this.setState({ areas: data })
    } catch (error) {
    //   console.error(error)
    }
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.areas.filter(area =>
      area.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };


  onKeyPressed = (e) => {
    if (e.key === 'Enter') {      
      let url = "/";
      const searcValue = $(".property_search_banner .react-autosuggest__input")
        .val()
        .split(', ')
        .join('-')
        .replace(/ /g, "-")
        .replace(/'/g, "")
        .toLowerCase();
  
      const price = $(".price-filter-dropdown").val();
      const rent_price = $(".rent-price-filter-dropdown").val();
      const ptype = $(".ptype").val();
  
      if (ptype === 'buy') {
        url = "/property/for-sale/";
      } else {
        url = "/property/to-rent/";
      }
  
      if (searcValue !== "") {
        url += "in-" + searcValue + "/";
      } else {
        url += "in-london/";
      }
  
      if (price !== "0" && ptype === 'buy') {
        url += "under-" + price + "/";
      }
      if (rent_price !== "0" && ptype === 'rent') {
        url += "under-" + rent_price + "/";
      }
  
      // Navigate to the constructed URL
      navigate(url);
    }
  };

  componentDidMount() {
    var url = process.env.GATSBY_STRAPI_SRC + "/json/areas.json" // process.env.GATSBY_STRAPI_SRC + "/areas"; // TODO: base URL supposed to be from .env   

    this.getitems(url)
    //this.setState({value: this.props.areaVal})
  }

  render() {
    const { value, suggestions, isChages, aliasVal } = this.state;

    
    // Autosuggest will pass through all these props to the input.
    var setVal = value;
    var setAliasVal = aliasVal;
    //console.log("aliasVal", aliasVal, setAliasVal);

    if (_.isEmpty(setVal) && !isChages && !_.isEmpty(this.props.areaVal)) {
      setVal = this.props.areaVal;
      setAliasVal = this.props.areaVal
    }

    const inputProps = {
      placeholder: this.props.type == "home-search" ? 'Postcode, Town or City' : "Street, area or postcode",
      value: setVal ? setVal.replace(/-/g, ' ') : '',
      onChange: this.onChange,
      //className: `form-control ${this.props.className}`
    };
    //console.log("areas =>", this.state.areas);

    // Finally, render it!
    return (
      <div className="form-group autosuggest_container">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.suggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...inputProps,
            onKeyDown: this.onKeyPressed,
          }}
        />
        <input type="hidden" className="searchVal" value={setAliasVal} />
        {/* <input type="hidden" className="searchVal" value={aliasVal} /> */}
      </div>
    );
  }
}

export default SearchResultBox;