import React,{useEffect, useState} from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import {Button, Card, Accordion, Container} from 'react-bootstrap';
import Starberry from "../../images/starberry.svg";
import CookieConsent from "../CookieConsent/CookieConsent";
import PopularSearch from "../popular-search/static"
import PopularSearchStaticDetails from "../popular-search/static-details"
import PopularSearchResults from "../popular-search/search-results"
import PopularSearchResultsCommercial from "../popular-search/search-results-commercial"
import PopularSearchDetails from "../popular-search/property-details"
import ReactMarkdown from "react-markdown";
import LoadExternalScript from "../utils/load-external-script";
import { GetURL } from "../common/site/functions";

import  "../footer/Footer.scss";

const locRatingScriptsJs = 'https://yuno-uat.s3.eu-west-2.amazonaws.com/yuno-widget-js/app_19022024.js';
const Footer = (props) => {
    const [toggleActive, settoggleActive] = useState(null)
    const toggleClick = (event) => {
    if(toggleActive === event){
    settoggleActive(null)
    }
    else {
    settoggleActive(event)

    }
    
    }
    const [newClass, setNewClass] = useState(false)


    const addNew = () => {
        setNewClass(!newClass)
    }

    const year = new Date().getFullYear();

  const data = useStaticQuery(graphql`
    query FooterQuery{
      strapiMenuFooter {
        Add_Column {
          Parent_Label
          Parent_URL {
            id
          }
          Add_Sub_Menus {
            Label
            URL {
              id
            }
          }
        }
      }

    strapiSiteConfig {
        Facebook_Link
        Instagram_Link
        Linkedin_Link
        Twitter_Link
        Youtube_Link
        Bottom_Navigations {
          Label
          Link {
            id
          }
        }
        Footer_Logos {
          Upload_Image {
            publicURL
          }
          Name
        }
      }

    }
  `);
  const social = (event,formType,formId,formName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': event,
      'formType': formType,
      'formId': formId,
      'formName': formName,
      'formLabel': formName
    });
  }
  const widgetHTML = `
  <style>
    :root {
      --yunoWidgetPrimaryColor: #4EAEA5;
    }
    #yunoBg {
      z-index: 999999 !important;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0px;
      left: 0px;
    }
    #yunoIframe {
      width: 100%;
      height: 100%;
    }
    #yunoWidget {
      z-index: 999999 !important;
      position: fixed;
      bottom: 16px;
      right: 50%;
      transform: translateX(50%);
      height: 60px;
      width: 60px;
      background-color: var(--yunoWidgetPrimaryColor);
      display: flex;
      border-radius: 3rem;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: all 100ms ease-in-out 0s;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
    }
    #yunoWidget:hover {
      cursor: pointer;
      width: 65px;
      height: 65px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9);
    }
    #yunoBubbleTipContainer {
      display: flex;
      justify-content: center;
    }
    #yunoBubbleTipContainer > svg {
      width: 15px;
      height: 8px;
      position: absolute;
      bottom: 54px;
      z-index: 999999999 !important;
    }
    #yunoContainer {
      z-index: 9999999 !important;
      position: fixed;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 90%;
      height: 80vh;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      padding: 2rem 3px 8px 3px;
      border-radius: 2px;
      background-color: var(--yunoWidgetPrimaryColor);
    }
    #yunoCross {
      position: fixed;
      top: 0rem;
      right: 0.5rem;
      padding: 0.3rem;
      margin: 0px;
    }
    #yunoCross:hover {
      cursor: pointer;
    }
    #yunoMessageBubble {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 60px;
      width: max-content;
      border-radius: 0.5rem;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      background-color: #fff;
      padding: 1rem;
      max-width: 70vw;
    }
    #yunoBubbleTitle {
      font-size: larger;
      margin: 0px;
      padding: 0px;
    }
    #yunoBubbleText {
        padding-top: 5px;
      font-size: medium;
      margin: 0px;
    }
    #bubbleTitleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    #bubbleTitleContainer > svg {
        padding-right: 4px;
        height: 24px;
        width: 24px;
    }
    #yunoCloseMessage {
        position: absolute;
        z-index: 10000000 !important;
        top: -16px;
        width: 32px;
        height: 32px;
        right: -12px;
        background-color: var(--yunoWidgetPrimaryColor);
        border-radius: 32px;
    }
    #yunoWidgetAlt {
      width: 64px;
      height: 64px;
      position: absolute;
    }
  </style>
  <div id="yunoBg" style="display: none" onClick="openYunoIframe()">
    <div id="yunoContainer">
      <p id="yunoCross">&#10006;</p>
      <iframe id="yunoIframe" src="https://app.goyuno.com"></iframe>
    </div>
  </div>
  <div id="yunoWidget" onclick="openYunoIframe()">
    <div id="yunoBubbleTipContainer">
      <svg id="yunoBubbleTip" width="28" height="6">
        <g filter="url(#a)">
          <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m37.5 22.5-15 15m0-15 15 15"/>
        </g>
        <defs>
          <filter id="a" width="60" height="60" x="-2" y="-1" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11100_61637"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_11100_61637" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
    <div id="yunoMessageBubble">
      <div onClick="yunoClose(event)">    
          <svg id="yunoCloseMessage" onClick="yunoClose(event)" xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none" viewBox="0 0 64 64">
            <g filter="url(#a)">
              <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m37.5 22.5-15 15m0-15 15 15"/>
            </g>
            <defs>
              <filter id="a" width="60" height="60" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11100_61637"/>
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_11100_61637" result="shape"/>
              </filter>
            </defs>
          </svg>
      </div>
      <div id="bubbleTitleContainer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
              <path stroke="#344054" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14 11.867V7.044c0-.383 0-.574-.05-.75a1.332 1.332 0 0 0-.211-.434c-.11-.147-.26-.265-.563-.5h0L8.655 1.843c-.234-.182-.351-.273-.48-.308a.667.667 0 0 0-.35 0c-.129.035-.246.126-.48.308L2.824 5.36c-.303.235-.454.353-.563.5-.096.13-.168.277-.212.433C2 6.47 2 6.661 2 7.043v4.824c0 .747 0 1.12.145 1.405.128.251.332.455.583.583.285.146.659.146 1.405.146h7.734c.746 0 1.12 0 1.405-.146.25-.128.455-.332.583-.582.145-.286.145-.66.145-1.406ZM8 6v2.667m0 2.666h.007"/>
          </svg>
          <h1 id="yunoBubbleTitle">Landlords</h1>
      </div>
      <p id="yunoBubbleText">Avoid a £30K fine with our free compliance checker.</p>
    </div>
    <img
      id="yunoWidgetImg"
      src="https://yuno-assets.s3.eu-west-2.amazonaws.com/YunoWidget.gif"
      height="50px"
      width="50px"
    />
    <svg id="yunoWidgetAlt" style="display: none;" xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64">
      <g filter="url(#a)">
        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m37.5 22.5-15 15m0-15 15 15"/>
      </g>
      <defs>
        <filter id="a" width="64" height="64" x="-2" y="-1" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="1"/>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11100_61637"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_11100_61637" result="shape"/>
        </filter>
      </defs>
  </svg>
  </div>
`;

  const [showYunoWidget, setShowYunoWidget] = useState(false);
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowYunoWidget(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return(
  <footer className={`footer components-footer-footer${props.footerClass ? ' ' + props.footerClass : ''}`}>
    <Container >
    {!props.hidemenu &&
     <>
                {props.popularSearch ==='propertyresults' || props.popularSearch ==='newhomespropertyresults' ?
                  <Accordion className="popular-search d-lg-block">
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                           <Link > Popular Searches</Link>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="101">
                        <Card.Body>
                        <PopularSearchResults searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                : props.popularSearch === 'commercialpropertyresults' ? 
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                        <PopularSearchResultsCommercial searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion> : props.Select_Popular_Search ?
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                <PopularSearch popularSearch={props.Select_Popular_Search} />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion> : ''
                }

                {props.popularSearch === 'staticdetails' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                  <PopularSearchStaticDetails office={props.office} area={props.area} guides={props.guide} page={props.page} />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }

                {props.popularSearch === 'propertydetails' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                    <PopularSearchDetails propertydetails={props.propertydetails} />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }

                {props.popularSearch === 'propertydetailsnewhomes' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                    <PopularSearchDetails propertydetails={props.propertydetails} pagetype="newhomes" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }

                {props.popularSearch === 'propertydetailscommercial' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                    <PopularSearchDetails propertydetails={props.propertydetails} pagetype="commercial" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }



        <Accordion className="footer-accordion">
        {data.strapiMenuFooter.Add_Column.map((Add_Column, i) => {
            let url = ''
            if(Add_Column.Parent_URL) {
                url = GetURL(Add_Column.Parent_URL.id)
            }
            return <Card className={i === 6 ? "block-management" : ''}>
                <Card.Header className={toggleActive === i+1 ? "minus" : ''}>
                <Accordion.Toggle as={Button} onClick={e=>toggleClick(i+1)} variant="link" eventKey={i+1}>
                    {url ?
                    <Link to={`/${url}`} className="menu-head">{Add_Column.Parent_Label}</Link> :
                    <span className="menu-head-foot-link">{Add_Column.Parent_Label}</span>
                    }
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i+1}>
                <Card.Body>
                <ul className="footer-nav">
                  {Add_Column.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                    let sub_menu_url = GetURL(Add_Sub_Menus.URL.id)
                    return <li><Link to={`/${sub_menu_url}`}><span>{Add_Sub_Menus.Label}</span></Link></li>
                  })}
                </ul>

                {i === 3 ? <>
                {data.strapiMenuFooter.Add_Column.slice(6).map((Add_Column, i) => {
                let url = GetURL(Add_Column.Parent_URL.id)
                return (
                <div className="block-management-desktop">
                <Link to={`/${url}`} className="menu-head">{Add_Column.Parent_Label}</Link>
                <ul className="footer-nav">
                  {Add_Column.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                    let sub_menu_url = GetURL(Add_Sub_Menus.URL.id)
                    return <li><Link to={`/${sub_menu_url}`}><span>{Add_Sub_Menus.Label}</span></Link></li>
                  })}
                </ul>
                </div>)
                })}
                </> : ''}


                </Card.Body>
                </Accordion.Collapse>
            </Card>
        })}


        </Accordion>
        </>
        }
        <div className="secondry-footer">
           <div className="footer-social-links d-flex justify-content-left align-items-center">
              <ul className="social-links d-flex align-items-center">
              {data.strapiSiteConfig.Facebook_Link ? <li><a href={data.strapiSiteConfig.Facebook_Link} target="_blank" onClick={() => social("facebook","facebook-icon","facebookID","fb-social-link-header")}><i className="icon-facebook"></i></a></li> : '' }
              {data.strapiSiteConfig.Twitter_Link ? <li><a href={data.strapiSiteConfig.Twitter_Link} target="_blank" onClick={() => social("twitter","twitter-icon","twitterID","tw-social-link-header")}><i className="icon-twitter"></i></a></li> : '' }
              {data.strapiSiteConfig.Instagram_Link ? <li><a href={data.strapiSiteConfig.Instagram_Link} target="_blank" onClick={() => social("instagram","instagram-icon","instagramID","insta-social-link-header")}><i className="icon-insta"></i></a></li> : '' }
              {data.strapiSiteConfig.Youtube_Link ? <li><a href={data.strapiSiteConfig.Youtube_Link} target="_blank" onClick={() => social("youtube","youtube-icon","youtubeID","youtube-social-link-header")}><i className="icon-youtube"></i></a></li> : '' }
              {data.strapiSiteConfig.Linkedin_Link ? <li><a href={data.strapiSiteConfig.Linkedin_Link} target="_blank" onClick={() => social("linkedin","linkedin-icon","linkedinID","linkedin-social-link-header")}><i className="icon-linkedin"></i></a></li> : '' }
              </ul> 
           </div>
           <div className="d-lg-flex justify-content-between">
              <div className="footer-brands d-flex justify-content-left align-items-center order-2">
                  {data.strapiSiteConfig.Footer_Logos && data.strapiSiteConfig.Footer_Logos.map((logo, i) => {
                      return (
                          <>
                              {logo.Upload_Image && 
                                  <a className={`${logo?.Name == "The Property Ombudsman" ? "tpo-logo" : ""}`}>
                                  <img loading="lazy" src={logo.Upload_Image.publicURL} alt="PartnerLogo"/>
                                  </a>
                              }
                          </>
                      )
                  })}
              </div>
              <div className="footer-info order-1">
                  <ul className="linkslist">
                      {data.strapiSiteConfig.Bottom_Navigations.map((links, i) => {
                      let url = GetURL(links.Link.id)
                          return (
                              <li><Link to={`/${url}`}>{links.Label}</Link> </li>
                          )
                      })}
                      <li><a href="javascript:void(0);" id="open_preferences_center" >Update Cookies Preferences</a></li>
                    
                  </ul>
                  <p className="copy-rgt">&copy; Copyright North London Network Limited {year}. All rights reserved.</p>
                  <p className="powerby">Site by <a href="https://starberry.tv/" className="powerby" target="_blank"><img loading="lazy" src={Starberry} alt="Starberry" className="stb-logo"/></a></p>
              </div>
          </div>
        </div> 
    </Container>
    
    {showYunoWidget && props?.showYunoWidget && 
      <>
          <LoadExternalScript src={locRatingScriptsJs}
              // async="true"
              // defer="true"
              // loadScript={renderMap}
              appendScriptTo="head" type={props?.page?.Show_Yuno_Widget} />  
          <div id="yuno-widget" title="Landlords" text="Avoid a £30K fine with our free compliance checker." url="https://martyngerrard.goyuno.com" ><ReactMarkdown source={widgetHTML} allowDangerousHtml /></div>
      </>
    }

    <CookieConsent/> 
          
  </footer>
)
}
export default Footer;