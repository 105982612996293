import React from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "@StarberryUtils";
import ReactMarkdown from "react-markdown/with-html";
import { useStaticQuery, graphql } from 'gatsby';

function PopularSearchStatic(props) {

    const data = useStaticQuery (graphql`
    query{
        strapiSiteConfig {
            Popular_Search_Static_About
            Popular_Search_Static_Common
            Popular_Search_Static_Contact
            Popular_Search_Static_Insights
            Popular_Search_Static_New_Homes
            Popular_Search_Static_Rentals
            Popular_Search_Static_Sales
          }


    }
    `);

    var popularSearchtype = props.popularSearch

        return (
            <>
            {popularSearchtype=='Static_Common' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_Common} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Static_Sales' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_Sales} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Static_Rentals' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_Rentals} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Static_About' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_About} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Static_New_Homes' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_New_Homes} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Static_Insights' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_Insights} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Static_Contact' ?
            <ReactMarkdown source={data.strapiSiteConfig.Popular_Search_Static_Contact} escapeHtml={false}/>
            : ''
            }
            </>
        )
}

export default PopularSearchStatic
