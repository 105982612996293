import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  connectHits,
  SearchBox,
  Pagination,
  Highlight,
} from 'react-instantsearch-dom';
import { Link } from "@StarberryUtils";
import $ from "jquery";
import _ from 'lodash';

const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const App = (props) => {
    return (
        <InstantSearch searchClient={searchClient} indexName={props.indexName}>
            <SearchBox
                className="form-control search-overlay-input"
                name="search"
                translations={{
                    placeholder: 'Search the website',
                }}
            />
            <Hits />
        </InstantSearch>
    );
}

function closeSearchOverlay(e) {
    $("body").removeClass("modal-open").find(".modal-backdrop").remove()
    $(".search-overlay").removeClass("active-search")
}

function ItemLinks(props) {
    const item = props.item;
    const i = props.iterator;
    if ((item.Main_Parent !== null) && (item.Sub_Parent === "")) {
        return (
            <li role="option" tabIndex={i}><Link to={"/"+item.Main_Parent.URL+"/"+item.url}>{item.pageName}</Link></li>
        );
    } else if((item.Main_Parent !== null) &&(item.Sub_Parent !== "")){
        return (
            <li role="option" tabIndex={i}><Link to={"/"+item.Main_Parent.URL+"/"+item.Sub_Parent+"/"+item.url}>{item.pageName}</Link></li>
        )
    }
    else {
        return (
            <li role="option" tabIndex={i}><Link to={"/"+item.url}>{item.pageName}</Link></li>
        );
    }
}

const ListItems = ( props ) => {
    const items = props.items;
    let iterator = 0;
    const listItems = items.map((item) => {
        iterator++;
        return (
            <ItemLinks key={item.objectID} iterator={iterator} item={item}/>
        )
    });
    return (
      <ul role="listbox">{listItems}</ul>
    );
}


const Hits = connectHits(({ hits }) => {
    hits = _.sortBy(hits, ['Sub_Parent_Name']);
    hits = _.groupBy(hits, hit => hit.Sub_Parent_Name);
    let itemSize = _.size(hits)
    return (
        <div>
            {itemSize ? (
                <div className="hit-feed">
                    <ul onClick={closeSearchOverlay}>
                        {Object.entries(hits).map((hit) => {
                            let items = hit[1]
                            let title = hit[0]
                            return (
                                <>
                                    {title ? <li className="title"><span>{title}</span>
                                        <ListItems items={items}/>
                                    </li> : 
                                        <ListItems items={items}/>
                                    }
                                </>
                            )
                        })}
                    </ul>
                </div>
            ) : (
                ''
            )}
        </div>
    )
});

export default App;
