import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
//import "animate.css/animate.css";
import $ from 'jquery'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL} from '../common/site/constants'

const PopularSearchDetails = (props) => {
    
        var propertydetails = props.propertydetails
        var typeText = ''
        var URL = ''
        var area = 'London'
        var ptypeURL= '/type-'+propertydetails.building

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }  
        if(propertydetails.search_type == 'sales') {
            typeText= 'for Sale'
            URL          = '/property/for-sale/'
            if(props.pagetype == 'newhomes') {
                URL = '/property/new-homes/for-sale/'
            }
            if(props.pagetype == 'commercial') {
                URL = '/property/commercial/for-sale/'
            }
        }
        if(propertydetails.search_type == 'lettings') {
            typeText= 'to Rent'
            URL          = '/property/to-rent/i'
            if(props.pagetype == 'newhomes') {
                URL = '/property/new-homes/to-rent/'
            }
            if(props.pagetype == 'commercial') {
                URL = '/property/commercial/to-rent/'
            }
        }

        return(
        <Row>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-london`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in North London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-east-finchley`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in East Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-barnet`}>{props.pagetype == 'newhomes' ? 'New ' : ''}{propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in Barnet</Link></li>
            </ul>
            </Col>

            {props.pagetype != "commercial" &&
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/1-and-more-bedrooms`}>1 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/2-and-more-bedrooms`}>2 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/3-and-more-bedrooms`}>3 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/4-and-more-bedrooms`}>4 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/5-and-more-bedrooms`}>5 Bedroom {propertydetails.building ? capitalizeFirstLetter(propertydetails.building) : 'Property'} {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            </ul>
            </Col>
            }


            {props.pagetype === "commercial" &&

              <Col md={6} lg={3}>
                <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}`}>Commercial Property {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-land`}>Lands {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-shop`}>Shops {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-office`}>Offices {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-industrial`}>Industrial Properties {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
                </ul>
              </Col>  
              }


            {props.pagetype != "commercial" &&
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}`}>Property {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-bungalow`}>Bungalows {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-apartment`}>Apartments {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-house`}>Houses {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${propertydetails?.address?.address2 ? propertydetails?.address?.address2?.replace(/\s/g , '-') : propertydetails.address.address1?.replace(/\s/g , '-')}/type-buy-to-let-investments`}>Buy to Let Investments {typeText} in {propertydetails?.address?.address2 ? propertydetails?.address?.address2 : propertydetails.address.address1}</Link></li>
            </ul>
            </Col>
            }
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${AREA_GUIDES_PAGE_URL.alias}`}>{capitalizeFirstLetter(area)} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in {capitalizeFirstLetter(area)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${ABOUT_PAGE_URL.alias}`}>About Martyn Gerrard</Link></li>
            <li><Link className="footer_scroll_top" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>{capitalizeFirstLetter(area)} Property Valuation</Link></li>
          </ul>
          </Col>  


          {props.pagetype === "commercial" &&
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-finchley`}>Commercial Property {typeText} in North Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-kentish-town`}>Commercial Property {typeText} in Kentish Town</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-mill-hill`}>Commercial Property {typeText} in Mill Hill</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-southgate`}>Commercial Property {typeText} in Southgate</Link></li>
          </ul>
          </Col>  
          }
        </Row>
    )

}

export default PopularSearchDetails;