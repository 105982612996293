import React from 'react'
import { useStaticQuery, graphql } from "gatsby";


export const GetURL = (id) => {
  const data = useStaticQuery(graphql`
    query {
	  allStrapiAllMenus {
	    edges {
	      node {
	        URL
	        Alias
	        Sub_Parent {
	          Alias
	          URL
	          id
	        }
	        Main_Parent {
	          Alias
	          URL
	          id
	        }
	        Link_Type
	        id
	        strapiId
	      }
	    }
	  }
    }
  `);

	let PageURL = data.allStrapiAllMenus.edges.filter(item => item.node.strapiId === id).pop().node;


 	let URL = PageURL.URL+'/'

  	if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent == null)) {
    	URL = PageURL.Main_Parent.URL + '/' +URL
  	}

  	else if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent != null)) {
    	URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' +URL
  	}

  	if(URL === 'property/new-homes/for-sale') {
  		URL = 'property/new-homes/for-sale/in-london'	
  	}
	else if(URL === 'property/commercial/for-sale') {
  		URL = 'property/commercial/for-sale/in-london'	
  	}

	return URL;

}


export const NewsDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${da} ${mo} ${ye}`}</span>
	)
}

export const ReviewDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${mo} ${ye}`}</span>
	)
}
export const EventDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<>{`${mo} ${da}`}</>
	)
}
export const EventShortDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<>{`${mo} ${da}`}</>
	)
}
export const EventEndDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<>{` - ${mo} ${da}`}</>
	)
}