import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
//import "animate.css/animate.css";
import $ from 'jquery'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL} from '../common/site/constants'

const StaticDetails = (props) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }  
    var SaleURL          = '/property/for-sale/'
    var RentURL          = '/property/to-rent/'
    var CommercialSaleURL          = '/property/commercial/for-sale/'
    var CommercialRentURL          = '/property/commercial/to-rent/'
    var NewHomesSaleURL          = '/property/new-homes/for-sale/'
    var NewHomesRentURL          = '/property/new-homes/to-rent/'
    var searcharea = capitalizeFirstLetter(props.area && props.area.replace(/-/g, ' '))

    return (<React.Fragment>
        
        <Row>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${props.area.replace(/\s/g , '-')}`}>Property for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${props.area.replace(/\s/g , '-')}/type-bungalow`}>Bungalows for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${props.area.replace(/\s/g , '-')}/type-apartment`}>Apartments for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${props.area.replace(/\s/g , '-')}/type-house`}>Houses for Sale in {searcharea}</Link></li>
            </ul>
            </Col>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${props.area.replace(/\s/g , '-')}`}>Property to rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${props.area.replace(/\s/g , '-')}/type-bungalow`}>Bungalows to rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${props.area.replace(/\s/g , '-')}/type-apartment`}>Apartments to rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${props.area.replace(/\s/g , '-')}/type-house`}>Houses to rent in {searcharea}</Link></li>
            </ul>
            </Col>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${NewHomesSaleURL}in-${props.area.replace(/\s/g , '-')}`}>New Homes for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${NewHomesRentURL}in-${props.area.replace(/\s/g , '-')}`}>New Homes to rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${CommercialSaleURL}in-${props.area.replace(/\s/g , '-')}`}>Commercial Property for sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${CommercialRentURL}in-${props.area.replace(/\s/g , '-')}`}>Commercial Property to rent in {searcharea}</Link></li>
            </ul>
            </Col>
            <Col md={6} lg={3}>
            <ul>

            {props.office &&
                <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}/${props.office.URL}`}>Estate agents in {searcharea}</Link></li>
            }

            {props.guides &&
                <li><Link className="footer_scroll_top" to={`${AREA_GUIDES_PAGE_URL.alias}/${props.guides.URL}`}>{searcharea} Area Guide</Link></li>
            }

            {props.guides == null && props.page=='office' &&
                <li><Link className="footer_scroll_top" to={`${AREA_GUIDES_PAGE_URL.alias}`}>London Area Guides</Link></li>
            }

            {props.office == null && props.page=='area' &&
                <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in North London</Link></li>
            }

            <li><Link className="footer_scroll_top" to={`${ABOUT_PAGE_URL.alias}`}>About Martyn Gerrard</Link></li>
            <li><Link className="footer_scroll_top" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>{searcharea} Property Valuation</Link></li>
            <li><Link className="footer_scroll_top" to={`${PROPERTY_SERVICES_PAGE_URL.alias}`}>North London Property Services</Link></li>
            </ul>
            </Col>
        </Row>
  
    </React.Fragment>)

}

export default StaticDetails;
