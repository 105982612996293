// This is the wrap component for GatsbyLink component to handle terlling slash for relative path
// Ref: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-link/src/index.js
//      https://reach.tech/router/nesting
import React from 'react';
import { Link } from "gatsby";
import { Location } from "@gatsbyjs/reach-router"
import { resolve } from "@gatsbyjs/reach-router/lib/utils"

function GatsbyLink({_location, children, ...props}) {
    const { to } =  props;

    // some urls defined like this <Link > Popular Searches</Link>, #, ?query=test
    // to avoid this kind of urls
    if (!to || to.endsWith('#') || to.match(/\?/)) {
        return (
            <Link {...props}>{children}</Link>
        )
    }

    let toPath = resolve(to, _location.pathname)
    if (!toPath.endsWith('/'))
        toPath = toPath + '/';

    toPath = toPath.toLowerCase();

    // if (!toPath.startsWith('/'))
    //     toPath = '/' + toPath
    // console.log("Test => ", toPath)
    // console.log("Test => ", props);
    // console.log("Test => ", children);
    return (
        <>
            <Link {...props} to={toPath}>{children}</Link>
        </>
    )
}

function GatsbyLinkLocationWrapper({...props}) {
  return (
    <Location>
      {({ location }) => <GatsbyLink {...props} _location={location} />}
    </Location>
  )
}

export default GatsbyLinkLocationWrapper;
