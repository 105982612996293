import React, { useState,useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
//import "animate.css/animate.css";
import $ from 'jquery'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDES_PAGE_URL} from '../common/site/constants'


const PopularSearchDynamicCommercial = (props) => {
  //Get values from refine search
  const [ptype,setPtype] = useState(false)
  const [searchbed,setsearchbed] = useState(false)


  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = 'London'
  var typeText = 'Commercial Property'
  var searcharea = ''
  var searchareaText=''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if(props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL          = '/property/commercial/for-sale/'
  }
  if(props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL          = '/property/commercial/to-rent/'
  }
  if(props.searchPtype) {
    ptypeURL   = '/type-'+props.searchPtype
    typeText     = capitalizeFirstLetter(props.searchPtype).replace("Buy-to-let-investments", "Buy to let investments")
  }
  if(props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield+' Bedroom '+typeText
    bedURL   = '/'+props.searchBedroomfield+'-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
    searcharea = props.Searcharea.areas
    searchareaText = props.Searcharea.areas.replace(/%20/g, " ")
  }
  return (<React.Fragment>
        <Row>
          {props.searchPtype ?
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london${ptypeURL}`}>{typeText} {searchaction} in London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-london${ptypeURL}`}>{typeText} {searchaction} in North London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-east-finchley${ptypeURL}`}>{typeText} {searchaction} in East Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-barnet${ptypeURL}`}>{typeText} {searchaction} in Barnet</Link></li>
          </ul>
          </Col>
          :<Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london${ptypeURL}`}>{typeText} {searchaction} in London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-london${ptypeURL}`}>{typeText} {searchaction} in North London</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-east-finchley${ptypeURL}`}>{typeText} {searchaction} in East Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-barnet${ptypeURL}`}>{typeText} {searchaction} in Barnet</Link></li>
          </ul>
          </Col>
          }

          <Col md={6} lg={3}>
            <ul>
            {props.searchPtype &&
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}`}>Commercial Property {searchaction} in {searchareaText}</Link></li>
            }
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-land`}>Lands {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-shop`}>Shops {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-office`}>Offices {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea.replace(/\s/g , '-')}/type-industrial`}>Industrial Properties {searchaction} in {searchareaText}</Link></li>
            </ul>
          </Col>

          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${AREA_GUIDES_PAGE_URL.alias}`}>{capitalizeFirstLetter(searchareaText)} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to={`${CONTACT_PAGE_URL.alias}`}>Estate Agents in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="footer_scroll_top" to={`${ABOUT_PAGE_URL.alias}`}>About Martyn Gerrard</Link></li>
            <li><Link className="footer_scroll_top" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>{capitalizeFirstLetter(searchareaText)} Property Valuation</Link></li>
          </ul>
          </Col>

          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-north-finchley`}>Commercial Property {searchaction} in North Finchley</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-kentish-town`}>Commercial Property {searchaction} in Kentish Town</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-mill-hill`}>Commercial Property {searchaction} in Mill Hill</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-southgate`}>Commercial Property {searchaction} in Southgate</Link></li>
          </ul>
          </Col>
        </Row>



  </React.Fragment>)

}

export default PopularSearchDynamicCommercial;
